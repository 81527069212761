import React, { ChangeEvent, useState } from 'react';
import { Box, Button } from '@mui/material';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { toast } from 'react-toastify';
import { LoadingButton } from '@mui/lab';
import { VerifiableCredential } from '@vckit/core-types';
import { decryptString } from '@govtechsg/oa-encryption';
import { computeEntryHash } from '@veramo/utils';
import moment from 'moment';

import { getLinkResolverJsonData } from '../services/api.service';
import CheckboxField from '../components/CheckboxField/CheckboxField';
import { AgtraceLinkResolver, AgtraceLinkResponse, IdentificationKeyType, LinkType, MimeType } from '../models/gs1';
import { buildDPPCredentialSubject, integrateVckitIssueVC } from '../services/vckit.service';
import { DDP_CONTEXT, TRANSFORMATION_EVENT_LD_CONTEXT } from '../models/vckit';
import { BucketName, uploadJson } from '../services/uploadJson.service';
import { createLinkResolver } from '../services/createLinkResolver.service';
import { epcisTransformationCrendentialRender, epcisTransformationCrendentialSubject } from '../services/epcis.service';
import { fetchNLISPIC, mockGtinData } from '../services/mock.service';
import { dppRenderTemplate } from '../models/vckit';
import { randomIntegerString } from '../utils/helpers';
import IssuerDropdown from '../components/IssuerDropdown/IssuerDropdown';
import { ErrorText } from '../models/common';
import DialogScanQrENVD from '../components/CustomDialog/ScanQrENVD/ScanQrENVD';
import { generateUUID } from '../utils/helpers';

/**
 * DigitalProduct component is used to display the button to upload eNVD VC and Process button.
 */
export const ProcessCows = () => {
    const [dlpLinkResolvers, setDlpLinkResolvers] = useState<any[]>([]);
    const [selectedNlisidItemList, setSelectedNlisidItemList] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);
    const [issuer, setIssuer] = useState('');
    const [openDialogScanQrENVD, setOpenDialogScanQrENVD] = useState(false);
    const clearRef = React.useRef<any>(null);

    /**
     * handleFileUpload is used to handle the file JSON upload and using consignmentNumber to get NLISID list from JSON file.
     */
    const handleFileUpload = (e: ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files) {
            return;
        }
        const files = e.target.files;

        for (let i = 0; i < files.length; i++) {
            const file = files[i];

            const fileReader = new FileReader();
            fileReader.onload = (event: ProgressEvent<FileReader>) => {
                const fileContent = event?.target?.result;
                const contentJsonFile = JSON.parse(fileContent as string);
                const consignmentNumber = contentJsonFile.credentialSubject.consignmentNumber;

                handleConsignmentList(consignmentNumber, contentJsonFile.credentialSubject.origin.pic);
            };

            fileReader.readAsText(file);
        }
    };

    const handleConsignmentList = (consignmentNumber: string, PIC: string) => {
        const consignmentNumbers: string[] = [];

        if (!consignmentNumber) return;
        if (!consignmentNumbers.includes(consignmentNumber)) {
            consignmentNumbers.push(consignmentNumber);
            getNLISIDs(consignmentNumber, PIC);
        }
    };

    /**
     * getListNLISID is used to get NLISID list from consignmentId.
     */
    const getNLISIDs = async (consignmentId: string, PIC: string) => {
        const nlisPIC = await fetchNLISPIC(consignmentId, PIC);
        const subset = nlisPIC[0]?.['Value']['SubSet'][0];
        const newSelectedFiles: any[] = [];

        const EPCISTransactionEvent = await getLinkResolverJsonData(subset.DigitalLinkResolver);
        const dlpLinks = EPCISTransactionEvent.credentialSubject.itemList.map((item: any) => {
            let link = item.link;
            const isVerifyPage = link.includes(process.env.REACT_APP_VERIFICATION_PAGE!);
            if (isVerifyPage) {
                const query = link.split('?q=')[1];
                const { payload } = JSON.parse(decodeURIComponent(query));
                const { uri } = payload;
                link = uri;
            }
            return { ...item, link };
        });
        newSelectedFiles.push(dlpLinks);

        setDlpLinkResolvers((prevState) => {
            const concatTwoArray = [...prevState, ...newSelectedFiles].flat().reduce((acc, current) => {
                acc[current.itemID] = current;
                return acc;
            }, {});

            return Object.values(concatTwoArray);
        });
    };

    /**
     * onChangeCheckBox is used to handle the change of the checkbox.
     */
    const onChangeCheckBox = (items: any[]) => {
        setSelectedNlisidItemList(items);
    };

    /**
     * change value of the issuer
     */
    const onChangeIssuerForm = (issuer: string) => {
        setIssuer(issuer);
    };

    const handleClickProcessBtn = async () => {
        setLoading(true);
        await processor(issuer);
    };

    const processor = async (issuer: string) => {
        try {
            const extractGTINs = (process.env.REACT_APP_GTINS || '').split(',');
            const vc = await issueEpcisTransformationEvent(issuer, extractGTINs);
            const transformantionEventLink = await uploadVC(`epcis-transformation-event/${generateUUID()}`, vc);

            const packingDate = moment().format('YYMMDD');

            await Promise.all(
                extractGTINs.map(async (gtin) => {
                    const serialNumber = randomIntegerString(8);
                    const qualifierPath = `/3101/000001/13/${packingDate}/21/${serialNumber}`;

                    const transformationEventLinkResolver = await registerTransformationEventLinkResolver(
                        transformantionEventLink,
                        IdentificationKeyType.gtin,
                        gtin,
                        'EPCIS transformation event VC',
                        qualifierPath,
                    );

                    const dpp = await issueDPP(
                        issuer,
                        gtin,
                        selectedNlisidItemList?.length,
                        transformationEventLinkResolver,
                    );
                    const DPPLink = await uploadVC(`${gtin}/${generateUUID()}`, dpp);
                    await registerLinkResolver(DPPLink, IdentificationKeyType.gtin, gtin, 'Digital Product Passport');
                }),
            );

            setLoading(false);
            setDlpLinkResolvers([]);
            clearRef!.current!.onClear();
            toast.success('Process successful', {
                position: 'bottom-right',
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                autoClose: 3000,
            });
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    };
    const restOfVC = { ...epcisTransformationCrendentialRender, type: ['TransformationEventCredential'] };
    const issueEpcisTransformationEvent = async (issuer: string, gtins: string[]) => {
        const result: VerifiableCredential = await integrateVckitIssueVC({
            context: TRANSFORMATION_EVENT_LD_CONTEXT,
            credentialSubject: epcisTransformationCrendentialSubject(
                selectedNlisidItemList.map((item) => item.value),
                gtins,
            ),
            issuer,
            restOfVC,
        });

        return result;
    };

    /**
     * upload VC to S3
     */
    const uploadVC = async (filename: string, vc: VerifiableCredential) => {
        const result = await uploadJson(filename, BucketName.PublicVC, vc);
        return result;
    };

    const registerLinkResolver = async (
        url: string,
        identificationKeyType: IdentificationKeyType,
        identificationKey: string,
        passportTitle: string,
    ) => {
        const linkResolver: AgtraceLinkResolver = {
            identificationKeyType,
            identificationKey: identificationKey,
            itemDescription: 'Digital Product Passport',
        };
        const query = encodeURIComponent(JSON.stringify({ payload: { uri: url } }));
        const queryString = `q=${query}`;
        const verificationPassportPage = `${process.env.REACT_APP_VERIFICATION_PAGE!}/?${queryString}`;
        const linkResponses: AgtraceLinkResponse[] = [
            {
                linkType: LinkType.verificationLinkType,
                linkTitle: 'VCKit verify service',
                targetUrl: (process.env.REACT_APP_VERIFICATION_TARGET_URL as string) || '',
                mimeType: MimeType.textPlain,
            },
            {
                linkType: LinkType.certificationLinkType,
                linkTitle: passportTitle,
                targetUrl: url,
                mimeType: MimeType.applicationJson,
            },
            {
                linkType: LinkType.certificationLinkType,
                linkTitle: passportTitle,
                targetUrl: verificationPassportPage,
                mimeType: MimeType.textHtml,
                defaultLinkType: true,
                defaultIanaLanguage: true,
                defaultMimeType: true,
            },
        ];

        await createLinkResolver(linkResolver, linkResponses, 'all', '/', queryString);
    };

    const registerTransformationEventLinkResolver = async (
        url: string,
        identificationKeyType: IdentificationKeyType,
        identificationKey: string,
        title: string,
        qualifierPath: string,
    ) => {
        const linkResolver: AgtraceLinkResolver = {
            identificationKeyType,
            identificationKey: identificationKey,
            itemDescription: 'EPCIS transformation event VC',
        };

        const query = encodeURIComponent(JSON.stringify({ payload: { uri: url } }));
        const queryString = `q=${query}`;
        const verificationPassportPage = `${process.env.REACT_APP_VERIFICATION_PAGE!}/?${queryString}`;
        const linkResponses: AgtraceLinkResponse[] = [
            {
                linkType: LinkType.epcisLinkType,
                linkTitle: title,
                targetUrl: url,
                mimeType: MimeType.applicationJson,
            },
            {
                linkType: LinkType.epcisLinkType,
                linkTitle: title,
                targetUrl: verificationPassportPage,
                mimeType: MimeType.textHtml,
                defaultLinkType: true,
                defaultIanaLanguage: true,
                defaultMimeType: true,
            },
        ];

        return await createLinkResolver(
            linkResolver,
            linkResponses,
            LinkType.epcisLinkType,
            qualifierPath,
            queryString,
        );
    };

    const issueDPP = async (issuer: string, gtin: string, numberOfCows: number, linkEpcis: string) => {
        const gtinData: { [k: string]: any } = mockGtinData(numberOfCows);
        const product = gtinData[gtin];

        if (!product) throw new Error('GTIN not found');

        const result: VerifiableCredential = await integrateVckitIssueVC({
            context: DDP_CONTEXT,
            credentialSubject: buildDPPCredentialSubject(gtin, product, linkEpcis),
            issuer,
            restOfVC: { ...dppRenderTemplate, type: ['ProductPassportCredential'] },
        });

        return result;
    };

    const onScanQRResult = (value: string) => {
        if (!value) return;
        handleScanValueAndFetchENVD(value);
    };

    const handleScanValueAndFetchENVD = async (value: string) => {
        try {
            setOpenDialogScanQrENVD(false);
            const parsedData = JSON.parse(value);
            if (!parsedData || !parsedData.uri || !parsedData.hash || !parsedData.key) {
                throw new Error('Invalid QR code');
            }
            const encryptedCredential = await getLinkResolverJsonData(parsedData.uri);

            let stringifyVC;
            try {
                // TODO: using the node-forge to decrypt the encryptedCredential
                stringifyVC = decryptString({
                    ...encryptedCredential,
                    key: parsedData.key,
                    type: 'OPEN-ATTESTATION-TYPE-1',
                });
            } catch (error) {
                errorToast('Unable to decrypt the verifiable credential');
                return;
            }

            const vc = JSON.parse(stringifyVC);
            const credentialHash = computeEntryHash(vc);

            if (credentialHash !== parsedData.hash) {
                errorToast('Credential hash mismatch detected');
                return;
            }
            handleConsignmentList(vc.credentialSubject.consignmentNumber, vc.credentialSubject.origin.pic);
        } catch (error) {
            errorToast('Invalid QR code');
            return;
        }
    };

    const errorToast = (message: string) => {
        toast.error(message, {
            position: 'bottom-right',
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            autoClose: 5000,
        });
    };

    return (
        <Box
            sx={{
                paddingTop: '40px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: '20px',
                }}
            >
                <Button
                    component='label'
                    variant='outlined'
                    startIcon={<UploadFileIcon />}
                    sx={{ marginRight: '1rem' }}
                >
                    Upload eNVD VC
                    <input type='file' accept='.json' hidden onChange={handleFileUpload} multiple />
                </Button>

                <Button variant='outlined' onClick={() => setOpenDialogScanQrENVD(true)} sx={{ marginRight: '1rem' }}>
                    ScanQR
                </Button>
            </Box>

            {dlpLinkResolvers?.length > 0 && (
                <>
                    <CheckboxField
                        data={dlpLinkResolvers.map((linkResolver) => {
                            return {
                                label: linkResolver.itemID,
                                value: linkResolver,
                            };
                        })}
                        onChangeCheckBox={onChangeCheckBox}
                    />
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <IssuerDropdown
                            onChangeIssuerForm={onChangeIssuerForm}
                            error={!issuer ? ErrorText.required : ''}
                            ref={clearRef}
                        />
                    </Box>
                    <LoadingButton
                        variant='outlined'
                        sx={{ width: { xs: '25%', md: '10%' }, marginTop: '20px' }}
                        onClick={handleClickProcessBtn}
                        disabled={selectedNlisidItemList.length === 0 || !issuer}
                        loading={loading}
                    >
                        Process
                    </LoadingButton>
                </>
            )}

            {openDialogScanQrENVD && (
                <DialogScanQrENVD
                    open={openDialogScanQrENVD}
                    close={() => setOpenDialogScanQrENVD(false)}
                    onScanQRResult={onScanQRResult}
                />
            )}
        </Box>
    );
};
