import * as React from 'react';
import { Box, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { routerLinks } from '../router/router';

/**
 * Home component is used to display the list of UI render from schemas.
 */
const Home: React.FC = () => {
    return (
        <Box
            sx={{
                marginTop: '64px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                height: '50vh',
                width: '100%',
                gap: '24px',
            }}
        >
            {routerLinks.map((page) => (
                <Button key={page.title} variant='contained' component={Link} to={page.path}>
                    {page.title}
                </Button>
            ))}
        </Box>
    );
};

export default Home;
