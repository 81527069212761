import { v4 as uuidv4 } from 'uuid';

export function convertBase64ToString(base64: string) {
    return Buffer.from(base64, 'base64').toString('utf8');
}

export function detectDevice(userAgent: string) {
    const userAgentLowerCase = userAgent.toLowerCase();

    if (/android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgentLowerCase)) {
        return 'mobile';
    } else if (/mac|win/i.test(userAgentLowerCase)) {
        return 'laptop';
    } else {
        return 'unknown';
    }
}

/**
 * @returns a random string of 5 characters
 */
export function randomString() {
    return Math.random().toString(36).slice(2, 7);
}

/**
 * @returns a random integer with number of digits specified by numberDigits
 * @example
 *  randomIntegerString(5) returns a random integer with 5 digits:
 *     12345
 *     00000
 *     00001
 */
export function randomIntegerString(numberDigits: number) {
    return Math.floor(Math.random() * 10 ** numberDigits)
        .toString()
        .padStart(numberDigits, '0');
}

export function generateUUID() {
    return uuidv4();
}

export function splitStringByDash(string: string) {
    return string.split('-');
}

export function extractStringFromURL(url: string) {
    const regex = /nlisid\/([A-Z0-9]{16})/;
    const match = url.match(regex);

    if (match) {
        return match[1];
    } else {
        return url;
    }
}

export function getCurrentGMTDateTime() {
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

    const now = new Date();

    const day = days[now.getUTCDay()];
    const date = now.getUTCDate();
    const month = months[now.getUTCMonth()];
    const year = now.getUTCFullYear();
    const hours = now.getUTCHours();
    const minutes = now.getUTCMinutes();
    const seconds = now.getUTCSeconds();

    return `${day}, ${date} ${month} ${year} ${hours}:${minutes}:${seconds} GMT`;
}
