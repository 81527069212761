import { Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel } from '@mui/material';
import React, { useState } from 'react';

type CheckboxData = {
    label: string;
    value: any;
};
interface CheckboxFieldProps {
    data: CheckboxData[];
    onChangeCheckBox: (listCheck: any[]) => void;
}

const CheckboxField = ({ data, onChangeCheckBox }: CheckboxFieldProps) => {
    const initialState = {} as { [key: string]: boolean };

    const [isCheck, setIsCheck] = useState(initialState);
    const [checkList, setCheckList] = useState<string[]>([]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let newArray = [...checkList, event.target.name];

        if (checkList.includes(event.target.name)) {
            newArray = newArray.filter((item) => item !== event.target.name);
        }

        setCheckList(newArray);
        setIsCheck({
            ...isCheck,
            [event.target.name]: event.target.checked,
        });

        const selectedItems = data.filter((item) => newArray.includes(item.label));

        onChangeCheckBox(selectedItems);
    };

    return (
        <FormControl component='fieldset'>
            <FormLabel component='legend'>NLISID</FormLabel>
            {data?.map(({ label, value }: CheckboxData, index: number) => (
                <FormGroup key={index}>
                    <FormControlLabel
                        control={
                            <Checkbox checked={isCheck[index]} onChange={handleChange} name={label} value={value} />
                        }
                        label={label}
                    />
                </FormGroup>
            ))}
        </FormControl>
    );
};

export default CheckboxField;
