import { IdentificationKeyType } from '../models/gs1';

export const mockGtinData = (numberOfCows: number) => {
    return {
        '9359502000034': {
            productClass: 'Beef Scotch Fillet',
            quantity: numberOfCows * 300,
            weight: 300,
            uom: 'kilogram',
            image: 'https://gs1ausaactivateprod1.blob.core.windows.net/935950200000/09359502000034/Beef-Scotch-Fillet-Steak-300g.png?sv=2019-07-07&sr=c&si=read&sig=1b9unDt%2FV7M0jCuNIbn47AaES0XK%2FOgL6QbRcuBGPOw%3D',
            description: 'Deforestation-free Beef Scotch Fillet',
        },
        '9359502000010': {
            productClass: 'Beef Rump Steak',
            quantity: numberOfCows * 250,
            weight: 250,
            uom: 'kilogram',
            image: 'https://gs1ausaactivateprod1.blob.core.windows.net/935950200000/09359502000010/Beef-Rump-Steak-250g.png?sv=2019-07-07&sr=c&si=read&sig=1b9unDt%2FV7M0jCuNIbn47AaES0XK%2FOgL6QbRcuBGPOw%3D',
            description: 'Deforestation-free Beef Rump Steak',
        },
        '9359502000041': {
            productClass: 'Beef Silverside',
            quantity: numberOfCows * 500,
            weight: 500,
            uom: 'kilogram',
            image: 'https://gs1ausaactivateprod1.blob.core.windows.net/935950200000/09359502000041/AgTace-Meats-Silverside.png?sv=2019-07-07&sr=c&si=read&sig=1b9unDt%2FV7M0jCuNIbn47AaES0XK%2FOgL6QbRcuBGPOw%3D',
            description: 'Deforestation-free Beef Silverside',
        },
    };
};

export const mockBatchIdData = () => {
    return {
        '19359502000017': {
            name: 'AgTrace Meats Beef Rump Steak 250 Gram x 12',
        },
        '19359502000031': {
            name: 'AgTrace Meats Beef Scotch Fillet Steak 300 Gram x 12',
        },
    };
};

export const fetchNLISPIC = async (consignmentNumber: string, PIC: string) => {
    const trustProvenancePICs = (process.env.REACT_APP_TP_PICS?.split(',') || []).map((pic) => {
        const [PICName, PIC] = pic.split('_');
        return {
            PICName,
            PIC,
        };
    });
    const isTrustProvenancePIC = trustProvenancePICs.find((pic) => pic.PIC === PIC);
    const dlr = isTrustProvenancePIC
        ? `${process.env.REACT_APP_TP_LINK_RESOLVER_URL}/${IdentificationKeyType.consignment_id}/${consignmentNumber}?linkType=gs1:epcis`
        : `${process.env.REACT_APP_FEEDLOT_LINK_RESOLVER_URL}/${IdentificationKeyType.consignment_id}/${consignmentNumber}?linkType=gs1:epcis`;

    const response = await Promise.resolve([
        {
            Value: {
                Offset: 0,
                Limit: 1,
                Total: 1,
                SubSet: [
                    {
                        PIC: PIC,
                        PICName: isTrustProvenancePIC ? isTrustProvenancePIC.PICName : 'AgTrace Test PIC',
                        PICRegisterId: 1,
                        BusinessName: '',
                        FirstName: 'JOHN',
                        LastName: 'DOE',
                        Town: 'NORTH SYDNEY',
                        Species: [
                            {
                                ShortCode: 'C',
                            },
                            {
                                ShortCode: 'S',
                            },
                        ],
                        PropertyTypes: [
                            {
                                ShortCode: 'PR',
                            },
                        ],
                        Brands: [
                            {
                                ShortCode: '5UA0',
                            },
                        ],
                        RegisteredState: 'NSW',
                        AdminStatus: {
                            ShortCode: 'A',
                            Description: 'Active',
                        },
                        ShireCode: 'CT',
                        ContactDetails: [
                            {
                                FirstName: 'JOHN',
                                LastName: 'DOE',
                                ContactTypeShortCode: 'PICMANAGERCONTACT',
                            },
                        ],
                        DigitalLinkResolver: dlr,
                    },
                ],
            },
        },
    ]);
    return response;
};
